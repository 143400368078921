.text-link:hover {
    text-decoration: underline;
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.font-semibold {
    font-weight: 600;
}

.grid-template {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    align-items: end;
    width: 100%;
}

.input {
    border: 1px solid rgb(209 213 219);
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    padding: 12px;
    border-radius: 0.375rem;
    width: 100%;
}

.select {
    margin-top: 0.25rem;

    height: 50px;
    display: block;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.cadastro-titulo {
    font-size: 1.25rem;
    font-weight: 600;

}

.divider-horizontal {
    border-top: 1px solid #e5e5e5;
    margin: 1rem 0;
}

.proponentes-container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: 100%;
}

.proponentes-titulo {
    font-size: 1.125rem;
    margin-bottom: 0.75rem;
    font-weight: 400;
}

.proponente-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    padding: 1rem;
}

.proponente-info {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.proponente-info h4 {
    font-weight: 600;
}

.proponente-info p {
    font-size: 0.875rem;
    color: #4b5563;
    margin-bottom: 0.25rem;
}

.proponente-actions {
    display: flex;
    gap: 0.75rem;
}

.proponente-actions button {
    background: none;
    border: none;
    cursor: pointer;
}

.edit-button {
    color: #3b82f6;
}

.delete-button {
    color: #ef4444;
}


.sem-proponentes {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
}

.sem-proponentes p {
    font-size: 1.125rem;
    font-weight: 300;
    color: #9ca3af;
    font-style: italic;
}

.botao-container {
    display: flex;
    justify-content: space-between;
}

.botao-adicionar {
    width: fit-content;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    gap: 0.25rem;
    border-radius: 0.375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.botao-adicionar span {
    color: #1e3a8a;
}

.botao-adicionar:hover {
    text-decoration: none;
}

.botao-proximo {
    width: fit-content;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    gap: 0.25rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.botao-proximo span {
    background-color: #0e2245;
    color: white;
    padding: 0.75rem 1.25rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
}

.botao-proximo span:hover {
    background-color: #1d3557;
}

.col-span-pessoais {
    grid-column: span 12;
}

@media (max-width: 640px) {

    .col-span-pessoais {
        grid-column: span 4;
    }
}

@media (min-width: 1024px) {

    .col-span-pessoais {
        grid-column: span 4;
    }
}
.col-span-rg {
    grid-column: span 12;
}

@media (max-width: 640px) {

    .col-span-rg {
        grid-column: span 2;
    }
}

@media (min-width: 1024px) {

    .col-span-rg {
        grid-column: span 2;
    }
}
.col-span-5 {
    grid-column: span 12;
}

@media (max-width: 640px) {

    .col-span-5 {
        grid-column: span 5;
    }
}

@media (min-width: 1024px) {

    .col-span-5 {
        grid-column: span 5;
    }
}

.col-span-contato {
    grid-column: span 12;
}

@media (max-width: 640px) {

    .col-span-contato {
        grid-column: span 6;
    }
}

@media (min-width: 1024px) {

    .col-span-contato {
        grid-column: span 6;
    }
}

.text-red-sm {
    color: #f87171;
    font-size: 0.875rem;
}

/* endereço */

.col-span-endereco {
    grid-column: span 12;
}

@media (max-width: 640px) {

    .col-span-endereco {
        grid-column: span 5;
    }
}

@media (min-width: 1024px) {

    .col-span-endereco {
        grid-column: span 5;
    }
}
.col-span-mini {
    grid-column: span 12;
}

@media (max-width: 640px) {

    .col-span-mini {
        grid-column: span 2;
    }
}

@media (min-width: 1024px) {

    .col-span-mini {
        grid-column: span 2;
    }
}

/* botão salvar */

.botao-salvar {
    color: #ffffff;
    background-color: rgb(34 197 94);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
}

.col-span-full {
    grid-column: span 12;
}

    