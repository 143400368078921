.text-link:hover {
    text-decoration: underline;
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.font-semibold {
    font-weight: 600;
}

.grid-template {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    align-items: end;
    width: 100%;
}

.input {
    border: 1px solid rgb(209 213 219);
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    padding: 12px;
    border-radius: 0.375rem;
    width: 100%;
}

.select {
    margin-top: 0.25rem;

    height: 50px;
    display: block;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.botao-salvar {
    color: #ffffff;
    background-color: rgb(34 197 94);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
}

.text-title {
    margin-bottom: 1rem;
    font-size: 1.125rem;
    font-weight: 400;
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
}

.data-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.375rem;
    padding: 1rem;
    border: 1px solid #d1d5db;
}

.data-info {
    display: flex;
    flex-direction: column;
}

.data-heading {

    font-size: large;
}

.data-subheading {
    font-size: 0.875rem;
    color: #4b5563;
}

.data-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.botao-salvar {
    color: #ffffff;
    background-color: rgb(34 197 94);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
}

.text-red-sm {
    color: #f87171;
    font-size: 0.875rem;
}

.custom-select .ant-select-selection-wrap {
    margin: auto !important;
}

.custom-select .ant-select-selection-overflow {
    padding: 10px !important;

}

.custom-select .ant-select-focused {
    box-shadow: none !important;
    border-color: initial !important;
}

.custom-select .ant-select-selector {
    border: 0px !important;
}

.custom-select .ant-select-selection-item {
    height: fit-content;
}

.ant-select-focused .ant-select-selector {
    outline: none !important;
    box-shadow: none !important;
}

.custom-select .ant-select-outlined {
    border: 1px !important;
}