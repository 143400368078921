/* @tailwind base;
@tailwind components;
@tailwind utilities; */

.menu-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-family: Arial, sans-serif;
    position: sticky;
    height: fit-content;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (min-width: 768px) {
    .menu-container {
        flex-direction: column;
        justify-content: flex-start;
        gap: 30px;
    }
}

.menu-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    transition: all 0.3s ease;
}

.menu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #d3d3d3;
}

.menu-icon .icon {
    color: #555;
    font-size: 20px;
}

.menu-text {
    font-size: 16px;
    color: #555;
    display: none;
}

@media (min-width: 768px) {
    .menu-text {
        display: flex;
    }
}

.menu-item:hover .menu-icon {
    background-color: #546D9A !important;
}



.menu-item:hover .icon {
    color: #fff !important;
}

.menu-item:hover .menu-text {
    color: #546D9A;
}

.divider {
    position: absolute;
    display: block;
    top: 50%;
    left: 0;
    right: 0;
    border-top: 2px solid #e5e7eb;
    z-index: -20 !important;
}

@media (min-width: 768px) {
    .divider {
        display: none;
    }
}