@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.div_delete_imovel:hover {
    cursor: pointer;
    background-color: rgb(216, 56, 56);
    color: white !important;

}

.div_link_imovel:hover {
    cursor: pointer;
    text-decoration: underline;

}

.popup_list {
    max-height: 900px;
    width: 500px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    transform: scale(1);
    transform-origin: bottom right;
}

.popup_list.hidden {
    opacity: 0;
    transform: scale(0);
    pointer-events: none;
}

.list_imoveis_icon {
    animation: fadeIn 0.4s ease-in-out;
}

/* html {
    overflow: hidden;
} */

.icon_close {
    cursor: pointer;
}

@media (max-width: 520px) {
    .popup_list {
        width: calc(100vw - 30px);
    }
}