.link-text:hover {
    text-decoration: underline;
    cursor: pointer;
}

.link-text {

    padding-top: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1rem;
    width: fit-content;
}

.success {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 12px;
}

.response {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 500;
}

.div-response {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.75rem;
}

.passo {
    display: flex;
    justify-content: flex-end;
    font-size: 1rem;
    color: #333;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.75rem;
}

.passo.w-full {
    width: 100%;
}

.passo.w-fit {
    width: fit-content;
}

.formulario {
    max-height: 60vh;
    min-width: 68vw;
    overflow-y: scroll;
    padding: 1.5rem;
}

.div-form {
    gap: 12px;
    display: flex;
    flex-direction: column;
}

@media (min-width: 768px) {
    .div-form {
        flex-direction: row;
    }
}

.divide-x> :not([hidden])~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}