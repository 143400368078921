.more-images {
    position: absolute;
    right: 30px;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;

    padding: 5px 1rem;
    color: #444;
    border-radius: .5rem;
    cursor: pointer;
    background-color: #eceff1;
    border: 1px solid #111;
    transition: all .2s ease;

    &:hover {
        background-color: #cfd8dc;
    }

    p {
        font-size: .8rem;
        font-weight: bold;
        margin: 0;
    }
}

@media screen and (max-width: 800px) {
    .more-images {
        display: none;
    }
}

.video-destaque {
    /* height: 600px !important; */
    width: 100% !important;
    aspect-ratio: 16/9 !important;
    top: -100%;
    left: -50%;
    transform: translate(50%, 50%);
    position: absolute;
}

.div-video {
    position: relative;
    height: calc(100vh - 200px);
    display: flex;
    align-items: center;
    justify-items: center;
}

@media screen and (max-width: 830px) {
    .div-video {
        max-height: 55vw;
        display: block;
    }

    .video-destaque {
        position: static;
        width: 100%;
        aspect-ratio: 16/9;
        transform: none;
        top: auto;
        left: auto;
    }
}