.text-link:hover {
    text-decoration: underline;
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.font-semibold {
    font-weight: 600;
}

.grid-template {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    align-items: end;
    width: 100%;
}

.input {
    border: 1px solid rgb(209 213 219);
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    padding: 12px;
    border-radius: 0.375rem;
    width: 100%; 
}

.select {
    margin-top: 0.25rem;

    height: 50px;
    display: block;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.botao-salvar {
    color: #ffffff;
    background-color: rgb(34 197 94);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
}

.col-span-input {
    grid-column: span 12;
}

@media (max-width: 640px) {

    .col-span-input {
        grid-column: span 6;
    }
}

@media (min-width: 1024px) {

    .col-span-input {
        grid-column: span 6;
    }
}

.text-red-sm {
    color: #f87171;
    font-size: 0.875rem;
}

.payment-method-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-x: auto;
    width: 100%;
}

.payment-method-title {
    margin-bottom: 1rem;
    font-size: 1.125rem;
    font-weight: 400;
}

.payment-method-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.375rem;
    padding: 1rem;
    border: 1px solid #d1d5db;
    width: 100%;
}

.payment-method-info {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.payment-method-heading {
    font-weight: 600;
    font-size: large;
}

.payment-method-subheading {
    font-size: 0.875rem;
    color: #3c434e;
}

.payment-method-action {
    display: flex;
    gap: 1rem;
    align-items: flex-end;
    justify-content: flex-end;
}

.payment-method-delete-button {
    color: #f87171;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
}

.payment-method-empty-message {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
}

.payment-method-empty-text {
    font-size: 1.125rem;
    font-weight: 300;
    color: #9ca3af;
    font-style: italic;
}

.botao-container {
    display: flex;
    justify-content: space-between;
}

.botao-adicionar {
    width: fit-content;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    gap: 0.25rem;
    border-radius: 0.375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.botao-adicionar span {
    color: #1e3a8a;
}

.botao-adicionar:hover {
    text-decoration: none;
}

.botao-proximo {
    width: fit-content;
    padding: 0px !important;
    gap: 0.25rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.botao-proximo span {
    background-color: #0e2245;
    color: white;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
}

.botao-proximo span:hover {
    background-color: #1d3557;
}